<template>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
            <b-form-group :label="$t('student_mobile_number')">
                {{ stateData.mobile_tel ? stateData.mobile_tel : '-' }}
            </b-form-group>
            <div v-if="tab == 3">
                <b-button v-if="!(mobileVerified || stateData.sms_approval_date)" variant="warning" size="sm"
                          @click="$refs.smsVerifyModal.$refs.commonModal.show()">{{ $t('verify') }}
                </b-button>
                <b-button v-if="mobileVerified || stateData.sms_approval_date" variant="success" size="sm">
                    {{ $t('verified') }}
                </b-button>
            </div>

        </div>
        <div class="col-12 col-sm-6 col-md-6">
            <b-form-group :label="$t('student_email')">{{ stateData.email ? stateData.email : '-' }}</b-form-group>
            <div v-if="tab == 3">
                <b-button v-if="!(emailVerified || stateData.email_approval_date)" variant="warning" size="sm"
                          @click="$refs.emailVerifyModal.$refs.commonModal.show()">{{ $t('verify') }}
                </b-button>
                <b-button v-if="emailVerified || stateData.email_approval_date" variant="success" size="sm">
                    {{ $t('verified') }}
                </b-button>
            </div>
        </div>
        <CommonModal ref="smsVerifyModal">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('verification') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="mobileForm">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_mobile_number')">
                                        <b-form-input
                                            v-model="smsVerifyForm.gsmNo"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-mask="'(+49)-##-########'"
                                            placeholder="(+49)-##-########"
                                            :value="smsVerifyForm.gsmNo"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between"
                             :class="[{'mb-4' : forgotPass},{'mb-0' : forgotPass}]">
                            <b-button type="submit" variant="primary" @click="infoUpdate()"
                                      v-show="smsSendButtonStatus">{{ $t('send') }}
                            </b-button>
                        </div>
                    </ValidationObserver>
                    <div class="visibility-hidden overflow-hidden h-0 fade"
                         :class="[{'visibility-visible, h-auto' : forgotPass},{'show' : forgotPass}]">
                        <b-alert :variant="alertVariant" :show="alertShow"><span
                            v-if="alertVariant == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                            v-if="alertVariant == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                        <ValidationObserver ref="smsVerifyActionForm">
                            <div class="mb-3">
                                <ValidationProvider name="smsVerifyCode" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i
                                                class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn
                                            </div>
                                        </div>
                                        <b-form-input
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="smsVerifyActionForm.verifyCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="d-flex justify-content-between">
                                <b-button type="submit" variant="primary" @click="gsmVerify">{{
                                        $t('verify')
                                    }}
                                </b-button>
                                <b-button type="submit" variant="outline-secondary" @click="EnterCodeAgainFunc()"
                                          v-show="smsSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="emailVerifyModal">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('verification') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="emailForm">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ValidationProvider name="email" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_email')">
                                        <b-form-input
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="emailForm.email"
                                            :value="emailForm.email"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between"
                             :class="[{'mb-4' : forgotPassEmail},{'mb-0' : forgotPassEmail}]">
                            <b-button type="submit" variant="primary" @click="emailUpdate"
                                      v-show="emailSendButtonStatus">{{ $t('send') }}
                            </b-button>
                        </div>
                    </ValidationObserver>
                    <ValidationObserver ref="emailVerifyForm">
                        <div class="visibility-hidden overflow-hidden h-0 fade"
                             :class="[{'visibility-visible, h-auto' : forgotPassEmail},{'show' : forgotPassEmail}]">
                            <b-alert :variant="alertVariantEmail" :show="alertShowEmail"><span
                                v-if="alertVariantEmail == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                                v-if="alertVariantEmail == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                            <div class="mb-3">
                                <ValidationProvider name="emailCode" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i
                                                class="ri-time-line top-plus-2 mr-1"></i>{{ timerCountEmail }} sn
                                            </div>
                                        </div>
                                        <b-form-input
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="emailVerifyFrom.emailCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="d-flex justify-content-between">
                                <b-button type="submit" variant="primary" @click="emailVerify">{{
                                        $t('verify')
                                    }}
                                </b-button>
                                <b-button type="submit" variant="outline-secondary" @click="EnterCodeAgainFuncEmail()"
                                          v-show="emailSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import CommonModal from "@/components/elements/CommonModal.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import OnlineRegistrationService from "@/services/OnlineRegistrationService";

export default {
    props: {
        stateData: {
            type: Object,
            default: null
        },
        tab: {
            type: Number,
            default: 6
        }
    },

    components: {
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },

    data() {
        return {
            alertShow: true,
            alertVariant: "success",
            timerEnabled: false,
            timerCount: 5,
            forgotPass: false,
            smsVerifyForm: {
                gsmNo: this.stateData.mobile_tel
            },
            smsSendButtonStatus: true,
            smsSendAgainButtonStatus: false,

            smsVerifyActionForm: {
                verifyCode: null
            },
            mobileVerified: this.stateData.mobileVerify,
            emailVerified: this.stateData.emailVerify,

            emailForm: {
                email: this.stateData.email
            },

            forgotPassEmail: false,
            timerEnabledEmail: false,
            timerCountEmail: 5,
            alertShowEmail: true,
            alertVariantEmail: "success",
            emailSendButtonStatus: true,
            emailSendAgainButtonStatus: false,

            emailVerifyFrom: {
                emailCode: null
            }
        }
    },

    watch: {
        timerEnabled(value) {
            this.timerCount--;
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false
                    this.smsSendAgainButtonStatus = false
                } else {
                    this.alertVariant = "danger"
                    this.alertShow = true
                    this.smsSendAgainButtonStatus = true
                }

            },
            immediate: true,
        },

        timerEnabledEmail(value) {
            this.timerCountEmail--;
        },
        timerCountEmail: {
            handler(value) {

                if (value > 0 && this.timerEnabledEmail) {
                    setTimeout(() => {
                        this.timerCountEmail--;
                    }, 1000);
                } else if (value > 0 && !this.timerEnabledEmail) {
                    this.alertShowEmail = false
                    this.smsSendAgainButtonStatus = false
                } else {
                    this.alertVariantEmail = "danger"
                    this.alertShowEmail = true
                    this.emailSendAgainButtonStatus = true
                }

            },
            immediate: true,
        }
    },

    methods: {
        async infoUpdate() {
            const isValid = await this.$refs.mobileForm.validate();
            if (isValid) {
                let gsmData = {
                    phone: this.smsVerifyForm.gsmNo,
                    token: this.stateData.token
                }

                OnlineRegistrationService.sendSms(gsmData).then(response => {
                    let data = response.data.data;

                    this.forgotPass = true
                    this.timerCount = data.expires_in
                    this.timerEnabled = true
                    this.smsSendButtonStatus = false
                    this.smsSendAgainButtonStatus = false
                }).catch(e => {
                    if (e.status == '404') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.message);
                        }
                    } else {
                        if (e.data.errors.phone) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.errors.phone[0]);
                        }
                        if (e.data.errors.token) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.errors.token[0]);
                        }
                    }
                })
            }

        },

        async gsmVerify() {

            const isValid = await this.$refs.smsVerifyActionForm.validate();
            if (isValid) {
                let smsData = {
                    token: this.stateData.token,
                    sms_code: this.smsVerifyActionForm.verifyCode
                }
                OnlineRegistrationService.verifySms(smsData).then(response => {
                    this.mobileVerified = true
                    this.$refs.smsVerifyModal.$refs.commonModal.hide()
                    this.stateData.mobile_tel = this.smsVerifyForm.gsmNo
                    this.$store.dispatch("onlineRegistration/restoreRegistrationMobile", this.smsVerifyForm.gsmNo)
                }).catch(e => {
                    if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(this.$t('api.' + e.data.message));
                        }
                    } else {
                        if (e.data.errors.phone) {
                            this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(e.data.errors.sms_code[0]);
                        }
                        if (e.data.errors.token) {
                            this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(e.data.errors.token[0]);
                        }
                    }
                })
            }

        },

        EnterCodeAgainFunc() {
            this.alertVariant = "success"
            this.infoUpdate()
        },

        async emailUpdate() {
            const isValid = await this.$refs.emailForm.validate();
            if (isValid) {
                let emailData = {
                    email: this.emailForm.email,
                    token: this.stateData.token
                }

                OnlineRegistrationService.sendEmail(emailData)
                    .then(response => {
                        this.forgotPassEmail = true
                        let data = response.data.data;
                        this.timerCountEmail = data.expires_in
                        this.timerEnabledEmail = true
                        this.emailSendButtonStatus = false
                        this.emailSendAgainButtonStatus = false
                    })
                    .catch(e => {
                        if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.emailForm.errors.email.push(this.$t('api.' + e.data.message));
                            }
                        } else {
                            if (e.data.errors.email) {
                                this.$refs.emailForm.errors.email.push(e.data.errors.email[0]);
                            }
                            if (e.data.errors.token) {
                                this.$refs.emailForm.errors.email.push(e.data.errors.token[0]);
                            }
                        }
                    })

            }
        },

        EnterCodeAgainFuncEmail() {
            this.alertVariantEmail = "success"
            this.emailUpdate()
        },

        async emailVerify() {
            const isValid = await this.$refs.emailVerifyForm.validate();
            if (isValid) {
                let formData = {
                    token: this.stateData.token,
                    email_code: this.emailVerifyFrom.emailCode
                }
                OnlineRegistrationService.verifyEmail(formData)
                    .then(response => {
                        this.emailVerified = true
                        this.$refs.emailVerifyModal.$refs.commonModal.hide()
                        this.stateData.email = this.emailForm.email
                        this.$store.dispatch("onlineRegistration/restoreRegistrationEmail", this.emailForm.email)
                    })
                    .catch(e => {
                        if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.emailVerifyForm.errors.emailCode.push(this.$t('api.' + e.data.message));
                            }
                        } else {
                            if (e.data.errors.email_code) {
                                this.$refs.emailVerifyForm.errors.emailCode.push(e.data.errors.email_code[0]);
                            }
                            if (e.data.errors.token) {
                                this.$refs.emailVerifyForm.errors.emailCode.push(e.data.errors.token[0]);
                            }
                        }
                    })
            }
        }
    }
}
</script>
