<template>
    <div class="row">
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('national_number')">{{ stateData.national_id }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('date_of_birth')">{{ stateData.birthdate }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('birthplace')">{{ stateData.birthplace }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('gender')">{{ stateData.gender }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('nationality')">{{ stateData.nationality_code }} -
                {{ stateData.nationality_code_name }}
            </b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('mother_name')">{{ stateData.mother_name }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('father_name')">{{ stateData.father_name }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('city_name')">{{ stateData.city_name }}</b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('district_name')">{{ stateData.district_name }}</b-form-group>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            stateData: {
                type: Object,
                default: null
            },
        }
    }
</script>
