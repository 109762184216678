<template>
    <div>
        <div v-for="(file,index) in this.stateData.fileTypes" v-bind:key="index">
            <b-row no-gutters class="file-upload-box mb-1" v-if="!file.hide">
                <b-col sm="12" md="6" lg="5">
                    <div class="filename d-flex" :class="{'active':file.file}">
                        <span class="flex-grow-1">{{ file.name }}</span>
                        <span v-if="file.required" class="text-danger"> *</span>
                    </div>
                </b-col>
                <b-col sm="12" md="6" lg="7">
                    <b-input-group>
                        <b-form-file
                            :ref="index"
                            v-model="files[index]"
                            :placeholder="file.file!=null?file.file.name:$t('select_file')+'...'"
                            @change="upload(index)"
                        >
                            <b-form-file id="file-small"></b-form-file>
                        </b-form-file>
                        <b-input-group-append>
                            <b-button variant="outline-secondary" class="btn-40 download"
                                      :v-b-popover.hover.right="$t('download')"
                                      v-if="file.file"
                                      @click="downloadFile(file.file.uuid)">
                                <i class="ri-download-2-line"></i>
                            </b-button>
                            <b-button variant="outline-secondary" class="btn-40"
                                      :v-b-popover.hover.right="$t('upload')"
                                      v-if="!file.file">
                                <i class="ri-add-fill"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </div>
        <div class="text-danger">* ile belirtilen alanlar zorunludur</div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import OnlineRegistrationService from "@/services/OnlineRegistrationService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        stateData: {
            default: null
        },
    },
    data() {
        return {
            fileTypes: [],
            files: [],
            uploadedFiles: [],
        }
    },
    created() {
        this.fileTypes = this.stateData.fileTypes;
        Object.keys(this.fileTypes).forEach(key => {
            this.fileTypes[key] = {
                ...this.fileTypes[key],
                'file': null,
            };
        });
    },
    mounted() {
        this.getFiles();
    },
    methods: {
        upload(index) {
            setTimeout(() => {
                if (this.files[index]) {
                    const formData = new FormData();
                    formData.append('token', this.stateData.token);
                    formData.append('document_id', index);
                    formData.append('file', this.files[index]);

                    OnlineRegistrationService.uploadFile(formData)
                        .then(() => {
                            this.getFiles();
                        })
                        .catch(error => {
                            if (error.status == 422) {
                                this.$toast.error(error.data.errors.file[0]);
                            }
                            if (error.status == 406) {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            }
                        })
                        .finally(() => {
                            this.files[index] = null;
                        });
                }
            }, 2000);
        },
        getFiles() {
            OnlineRegistrationService.getFiles({token: this.stateData.token})
                .then(response => {
                    const data = response.data.data;
                    Object.keys(data).forEach(key => {
                        this.fileTypes[key].file = data[key];
                    });
                })
        },
        downloadFile(uuid) {
            OnlineRegistrationService.downloadFile({token: this.stateData.token, uuid: uuid})
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                    //document.execCommand('SaveAs',true, objectUrl);
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        }
    }
}
</script>
